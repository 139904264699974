<template>
    <div>
        <section id="content">
            <div v-for="step in projectReflection" :key=step.step_id>
                <div style="display: flex; flex-direction: row; border-bottom: 2px solid">
                    <div class="mx-3" style="display: flex; align-items: center">
                        <b-form-checkbox :id="`checkbox-${step.step_id}`" name="checkbox1" value="true"
                            unchecked-value="false"
                            :checked="isProjectReflectionStepFinished(step.step_id)"></b-form-checkbox>
                    </div>
                    <div class="mx-3" style="display: flex; align-items: center">
                        <strong>{{ step.step_id }}</strong>
                    </div>
                    <div style="width: 100%">
                        <strong>{{ step.title }}</strong>
                    </div>

                </div>
                <div style="margin-bottom: 40px">
                    <div class="mt-3" v-for="question in step.reflection_questions" :key="step.step_id + question.id"
                        style="margin-bottom: 25px">
                        <div v-if="question.cat === 'none' || getFavoritesFilter.includes(question.cat)">
                            <div style="display: flex; flex-direction: row;">
                                <div class="mx-3" style="display: flex; align-items: center">
                                    <b-form-checkbox :id="`checkbox-${step.step_id}-${question.id}`" name="checkbox"
                                        value="true" unchecked-value="false" type="checkbox"
                                        :checked="question.checked"></b-form-checkbox>
                                    <span style="margin-left: 10px" v-html="question.text"></span>
                                    <b-icon v-show="getFavoritesFilter.includes(question.cat)" icon="star-fill"
                                        :style="{ color: '#F2AF4A' }"></b-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mt-5">
                <b>Eigene Notizen</b>
                <p>{{ getProjectReflectionNote }}</p>
            </div>
        </section>
    </div>
</template>

<script>

import NavSidebar from "../../../components/Nav_Sidebar.vue"
import NavHeader from "../../../components/Nav_Header.vue"
import HeaderCard from "../../../components/Header_Card.vue"
import OverviewCollapse from "../../../components/Overview_Collapse.vue"
import CollapseReflection from "../../../components/Collapse_Reflection.vue"
import MyModal from "../../../components/Modal.vue"
import FavItem from "./FavItem.vue"
import ValueReflection from "../valueReflection/ValueReflection.vue"
import Profile from "../profile/Profile.vue"

export default {
    name: 'projectreflectionPDF',
    props: {
        isOverview: Boolean,
    },
    components: {
        NavSidebar,
        NavHeader,
        HeaderCard,
        OverviewCollapse,
        CollapseReflection,
        MyModal,
        FavItem,
        ValueReflection,
        Profile
    },
    computed: {
        getFavoritesFilter() {
            return this.$store.getters['survey/getProjectReflectionFavorits']
        },
        getAllClassification() {
            return this.$store.getters['survey/getAllCategories']
        },
        projectReflection() {
            return this.$store.getters['survey/getProjectReflection']
        },
        getProjectReflectionNote() {
            return this.$store.getters['survey/getProjectReflectionNote']
        },
        showProjectReflection() {
            return this.$store.getters['survey/showProjectReflection']
        },
        isCheckedProjectReflection() {
            let payload = { step_id: this.step_id, question_id: this.question.id }
            return this.$store.getters['survey/getProjectReflection_QuestionCheck'](payload)
        }

    },
    methods: {
        clickEdit() {
            this.$router.push('/reflexion')
        },
        isProjectReflectionStepFinished(stepId) {
            return this.$store.getters['survey/isProjectReflectionStepFinished'](stepId)
        },
        showTutorial() {
            this.isShowTutorial = !this.isShowTutorial
        },

    },
    beforeMount() {
        this.projectReflectionNote = this.$store.getters['survey/getProjectReflectionNote']
    },
    data() {
        return {
        }
    }
}

</script>

<style lang="scss" scoped>
section#content {
    display: flex;
    flex-direction: column;
}
</style>
